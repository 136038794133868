<template>
  <v-app>
    <div>
      <p>404 | Página não encontrada</p>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
p {
  font-size: 32px;
  color: dimgray;
  text-align: center;
}
div {
  width: 100%;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  position: absolute;
}
</style>
